import React from "react"
import { graphql } from "gatsby"
import Image from "./image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import style from "./project.module.scss"

export const query = graphql`
  query($name: String!) {
    file(relativeDirectory: { eq: $name }, name: { eq: "info" }) {
      childYaml {
        title
        credits {
          what
          who
        }
        subtitles {
          title
          images
        }
      }
    }
    allFile(
      sort: { fields: [relativePath] }
      filter: {
        childImageMetadata: { internal: { type: { eq: "ImageMetadata" } } }
        relativeDirectory: { eq: $name }
      }
    ) {
      edges {
        node {
          ...Image
        }
      }
    }
  }
`

class ProjectsPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      childYaml: { title, credits, subtitles },
    } = props.data.file || {
      childYaml: { title: props.pageContext.name, credits: [], subtitles: [] },
    }
    this.title = title.replace(/\t/g, "")
    this.credits = credits || []

    this.titles = []
    this.titlesMap = []
    subtitles &&
      subtitles.forEach(({ title: subtitle, images }) => {
        const position = this.titles.length
        this.titles.push(subtitle)
        this.titlesMap = this.titlesMap.concat(Array(images).fill(position))
      })

    this.scrollTimeout = false
    this.closed = false
    this.page = 0
    this.lastKnownScroll = 0

    this.imgProps = {
      onClick: this.imageClick,
      onKeyDown: this.imageKeyDown,
      role: "button",
      tabIndex: "0",
    }
    this.state = {
      imgProps: this.imgProps,
      title: this.title,
      titleStyle: style.title,
    }
  }

  componentDidMount() {
    this.parseHash(window.location.hash)
    window.addEventListener("popstate", e => {
      this.parseHash(window.location.hash)
    })
  }

  componentWillUnmount() {
    if (this.scrollTimeout) {
      window.clearTimeout(this.scrollTimeout)
    }
  }

  parseHash = hash => {
    if (hash) {
      const page = parseInt(hash.slice(1), 10)
      this.openGalleryTo(page)
    } else {
      this.closeGallery()
    }
  }

  previous = e => {
    let rounded = Math.floor(this.page)
    if (this.page - rounded < 0.1) {
      this.scrollTo(rounded - 1, true)
    } else {
      this.scrollTo(rounded, true)
    }
  }

  next = e => {
    let rounded = Math.ceil(this.page)
    if (rounded - this.page < 0.1) {
      this.scrollTo(rounded + 1, true)
    } else {
      this.scrollTo(rounded, true)
    }
  }

  scrollTo = (p, smooth = false) => {
    let el = document.querySelector(`div[data-key="${p}"] > .${style.img}`)
    if (el) {
      document.querySelector("." + style.images).scroll({
        top: 0,
        left: el.offsetLeft,
        behavior: smooth ? "smooth" : "auto",
      })
    }
  }

  onScroll = e => {
    if (this.closed) return
    this.lastKnownScroll = e.target.scrollLeft

    if (!this.scrollTimeout) {
      this.scrollTimeout = window.setTimeout(() => {
        if (!this.scrollTimeout || this.closed) {
          return
        }
        this.scrollTimeout = false
        const width = document
          .querySelector("." + style.img)
          .getBoundingClientRect().width
        const page = this.lastKnownScroll / width
        this.page = page
        const roundedPage = Math.round(page)
        window.history.replaceState(roundedPage, this.title, "#" + roundedPage)
        this.updateTitle(roundedPage)
      }, 100)
    }
  }

  updateTitle = page => {
    if (page === "close") {
      return this.setState({
        title: this.title,
        titleStyle: style.title,
      })
    }
    const title = this.titles[this.titlesMap[page]] || this.title
    if (this.state.title !== title) {
      const titleStyle = style.subtitle
      this.setState({ title, titleStyle })
    }
  }

  imageClick = e => {
    let index = e.currentTarget.dataset.key
    this.openGalleryTo(index)
    window.history.pushState(index, this.title, "#" + index)
  }

  imageKeyDown = e => {
    if (e.key === "Enter") {
      this.imageClick(e)
    }
  }

  closeClick = e => {
    this.closeGallery()
    window.history.pushState(null, this.title, "#")
  }

  openGalleryTo = page => {
    this.closed = false
    this.setState({ imgProps: {} })
    this.updateTitle(page)
    const wrapper = document.querySelector("." + style.wrapper)
    if (wrapper) {
      wrapper.className = style.wrapperSlideshow
    }
    const mainNav = document.querySelector(".main-nav")
    if (mainNav) {
      mainNav.style.display = "none"
    }
    this.scrollTo(page)
  }

  closeGallery = () => {
    this.closed = true
    if (this.scrollTimeout) {
      window.clearTimeout(this.scrollTimeout)
      this.scrollTimeout = false
    }
    this.setState({ imgProps: this.imgProps })
    this.updateTitle("close")
    const wrapper = document.querySelector("." + style.wrapperSlideshow)
    if (wrapper) {
      wrapper.className = style.wrapper
    }
    const mainNav = document.querySelector(".main-nav")
    if (mainNav) {
      mainNav.style.display = null
    }
  }

  imageStyle = metadata => {
    const ratio = metadata.aspectRatio
    const height = 250 + Math.random() * 50
    const width = height * ratio
    const margin = () => Math.random() * 50 - 10
    return {
      height: `${height}px`,
      width: `${width}px`,
      margin: `${margin()}px ${margin()}px ${margin()}px ${margin()}px`,
    }
  }

  render() {
    return (
      <Layout fixedHeight>
        <div className={style.wrapper}>
          <SEO title={this.title} />
          <h1 className={this.state.titleStyle}>{this.state.title}</h1>
          <dl className={style.credits}>
            {this.credits.map(({ what, who }, i) => {
              return (
                <div key={i}>
                  <dt>{what}</dt>
                  <dl className="handwriting">{who}</dl>
                </div>
              )
            })}
          </dl>
          <div className={style.close}>
            <button title="Close" onClick={this.closeClick}>
              ×
            </button>
          </div>
          <div className={style.gallery}>
            <button onClick={this.previous} title="Previous">
              <span />
            </button>
            <div className={style.images} onScroll={this.onScroll}>
              {this.props.data.allFile.edges.map(({ node }, index) => {
                let metadata = node.childImageMetadata
                if (!metadata) return
                let inlineStyle = this.imageStyle(metadata)
                return (
                  <>
                    {this.titlesMap[index - 1] !== this.titlesMap[index] && (
                      <h2>{this.titles[this.titlesMap[index]]}</h2>
                    )}
                    <div
                      key={index}
                      data-key={index}
                      className="custom-focus"
                      {...this.state.imgProps}
                    >
                      <Image
                        className={style.img}
                        metadata={metadata}
                        style={inlineStyle}
                        sizes={
                          this.closed
                            ? inlineStyle.width
                            : `(max-aspect-ratio: ${metadata.aspectRatio.toFixed(
                                2
                              ) * 100}/100) 100vw, ${metadata.aspectRatio *
                                100}vh`
                        }
                      />
                    </div>
                  </>
                )
              })}
            </div>
            <button onClick={this.next} title="Next">
              <span />
            </button>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProjectsPage
