import React from "react"

const Image = ({ className, metadata, style, sizes = "100vw" }) => {
  let srcSet = metadata.sizes
    .reduce((a, c) => a + `${encodeURI(c.path.publicURL)} ${c.width}w, `, "")
    .slice(0, -2)
  let fallback = metadata.sizes[metadata.sizes.length - 1]
  return (
    <img
      className={className}
      srcSet={srcSet}
      sizes={sizes}
      style={style}
      src={encodeURI(fallback.path.publicURL)}
    />
  )
}

export default Image

export const query = graphql`
  fragment Image on File {
    childImageMetadata {
      aspectRatio
      sizes {
        width
        height
        path {
          publicURL
        }
      }
    }
  }
`
